import * as React from 'react'
import { AppBar, Box, Toolbar, Tooltip,  IconButton, Typography, Menu, MenuItem, Container, Avatar, Divider, Badge, useTheme } from '@mui/material'
import { red, blue } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import LaunchIcon from '@mui/icons-material/Launch';
import SecurityIcon from '@mui/icons-material/Security';
import VerifiedIcon from '@mui/icons-material/Verified';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';



export default function TopNav(props) {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null)
    const theme = useTheme()
    const colorMode = theme.palette.mode
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    }
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    function handleMobileNav(page) {
        handleCloseNavMenu()
    }
    function handleChangeTheme() {
      if(props.themeColor === 'light') {
        props.setThemeColor('dark')
        localStorage.setItem('theme', 'dark')
      } else {
        props.setThemeColor('light')
        localStorage.setItem('theme', 'light')
      }
      handleCloseUserMenu()
    }
    const VerifiedUser = (
      // ':active': {height: 20, width: 24}, transition: 'all 0.125s'
      <Tooltip title="Verified User">
          <VerifiedIcon sx={{verticalAlign: 'middle', color: colorMode === 'dark' ? blue[200] : null}} />
      </Tooltip>
    )
    const TestAccount = (
      <Box sx={{display: 'flex', justifyContent: 'center', bgcolor: red[600], ml:{xs: '0', md: '240px'}, textAlign: 'center', p:1}}>
        <Typography>
          <WarningAmberIcon sx={{verticalAlign: 'middle', mr: 1}} />
          This is a test account made for the purpose of testing only
          <WarningAmberIcon sx={{verticalAlign: 'middle', ml: 1}} />
        </Typography>
      </Box>
    )
    return (
    <AppBar position='static'>
        <Container maxWidth>
        <Toolbar disableGutters>
            <Box sx={{ flexGrow: 1 }}>
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    color="inherit"
                >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
                <MenuItem component={Link} to='lookup' onClick={handleMobileNav}>Lookup</MenuItem>
                <MenuItem component={Link} to='create' onClick={handleMobileNav}>Create</MenuItem>
                <MenuItem component={Link} to='delete' onClick={handleMobileNav}>Delete</MenuItem>
                <MenuItem component={Link} to='roles' onClick={handleMobileNav}>Roles</MenuItem>
                <MenuItem component={Link} to='admins' onClick={handleMobileNav}>Admins</MenuItem>
                <MenuItem component={Link} to='ban' onClick={handleMobileNav}>Ban/Pardon</MenuItem>
                <Divider />
                <MenuItem component={Link} to='settings/index' onClick={handleMobileNav}>Index Settings</MenuItem>
                <MenuItem component={Link} to='settings/shop' onClick={handleMobileNav}>Shop Settings</MenuItem>
                <MenuItem component={Link} to='settings/accounts' onClick={handleMobileNav}>Accounts Settings</MenuItem>
                <MenuItem component={Link} to='settings/archive' onClick={handleMobileNav}>Archive Settings</MenuItem>
                <MenuItem component={Link} to='settings/global' onClick={handleMobileNav}>Global Settings</MenuItem>
            </Menu>
            </Box>
            <Typography className='userName'>Welcome, 
               {props.displayName ?  ' ' + props.displayName : ' ' + props.email}
               {props.verified === true ? VerifiedUser : null}
            </Typography>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton sx={{ p: 0 }} onClick={handleOpenUserMenu}>
                <Badge
                  overlap='circular'
                  anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                  badgeContent={
                    <SecurityIcon />
                  }
                >
                  <Avatar alt={props.displayName} src={props.userAvatar} sx={{width:{xs: 48, sm: 56}, height: {xs: 48, sm: 56}}} />
                </Badge>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
                <MenuItem component='a' href='//accounts.thehigherups.org' target='_blank' rel='noopener'>
                  <Typography textAlign="center">Account Settings <LaunchIcon sx={{fontSize: '0.75rem   ', verticalAlign: 'top'}} /> </Typography>
                </MenuItem>
                <MenuItem onClick={() => props.logoutUser()}>
                  <Typography textAlign="center"><LogoutIcon sx={{verticalAlign: 'middle'}} /> Logout</Typography>
                </MenuItem>
                <MenuItem onClick={() => handleChangeTheme()}>
                  <Typography textAlign="center">
                  {props.themeColor === 'light' ? 
                  <><DarkModeIcon sx={{verticalAlign: 'middle'}}/> Dark Mode </> :
                  <><LightModeIcon sx={{verticalAlign: 'middle'}} /> Light Mode </>}
                  </Typography>
                </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      {props.testAccount ? TestAccount : null}
    </AppBar>
    )
}