import * as React from 'react'
import { Box, Button, Grid, Card, TextField, Typography, Alert, Collapse } from "@mui/material"
import { ReactComponent as Logo } from '../HigherUpsSeal.svg'
import { sendPasswordResetEmail, onAuthStateChanged } from 'firebase/auth'
import { auth } from '../firebase'
import { CheckErrorCode } from '../firebase-errors'
import { useNavigate, Link } from 'react-router-dom'

export default function PasswordReset(props) {
    let navigate = useNavigate()
    const [email, setEmailValue] = React.useState('')
    const [id, setIdValue] = React.useState('')
    const [alertActive, setAlertActive] = React.useState(false)
    const [alertText, setAlertText] = React.useState('')
    const [alertType, setAlertType] = React.useState('success') // error, warning, info, success 
    React.useState(() => {
        onAuthStateChanged(auth, (user) => {
            if(user) {
                navigate('/')
            }
        })
    }, [])
    function handlePasswordReset(event) {
        event.preventDefault()
        document.querySelector('form').reset()
        sendPasswordResetEmail(auth, email)
            .then(() => {
                setAlertType('success')
                setAlertText('Password Reset Email Sent Successfully')
                setAlertActive(true)
                closeAlert()
                setEmailValue('')
                setIdValue('')
            })
            .catch((error) => {
                const errorCode = CheckErrorCode(error.code)
                setAlertType('error')
                setAlertText(errorCode)
                setAlertActive(true)
                closeAlert()
            })
    }
    function closeAlert() {
        setTimeout(()=> {
            setAlertActive(false)
        }, 4000 )
    }
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
        >
            <Grid item xs={3} sx={{minWidth: { xs: '300px', sm: '500px'}}} >
                <Card component='form' sx={{display: 'flex', flexDirection: 'column', gap: '1.25rem', padding: '2rem'}} elevation={3} onSubmit={handlePasswordReset} autoComplete='off'>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', gap: '0.5rem'}}>
                        <Logo/> 
                        <Typography component='h1' variant='h4' >
                            Reset Password
                        </Typography>
                        <Typography variant='subtitle1'>Reset Your Password If You Forgot It</Typography>
                    </Box>
                    <TextField 
                        label="Email" 
                        type='email'
                        fullWidth
                        onChange={(event) => setEmailValue(event.target.value)}
                        value={email}
                        required
                    />
                    <TextField 
                        label="HigherUps-ID"
                        type='text' 
                        onChange={(event) => setIdValue(event.target.value)}
                        value={id}
                        fullWidth
                        id='password'
                    />
                    <Button 
                        type="submit"
                        variant="contained"
                    >
                        Reset Password 
                    </Button>
                    <Collapse in={alertActive}>
                        <Alert severity={alertType}>
                            {alertText}
                        </Alert>
                    </Collapse>
                    <Button component={Link} replace to='/login'>
                        Return to Sign In
                    </Button>
                </Card>
            </Grid>
        </Grid>
    )
}