export function CheckErrorCode(errorCode) {
    switch(errorCode) {
        case 'auth/claims-too-large':
            return "User Claims are too large"
        case 'auth/email-already-exists':
            return "An account with that email already currently exists"
        case 'auth/id-token-expired':
            return "The provided ID token has expired"
        case 'auth/id-token-revoked':
            return "The provided ID token has been revoked"
        case 'auth/insufficient-permission':
            return "The credential used have insufficient permission to access the requested Authentication resource"
        case 'auth/internal-error':
            return "An error occured with the Authentication server trying to process the request"
        case 'auth/invalid-claims':
            return "The custom claims provided are invalid"
        case 'auth/invalid-creation-time':
            return "The creation time is invalid"
        case 'auth/invalid-disabled-field':
            return "The value for the display name is invaild"
        case 'auth/invalid-display-name':
            return "The value for the display name is invaild"
        case 'auth/invalid-email':
            return "The email provided is invalid"
        case 'auth/invalid-email-verified':
            return "The value for the email verified property is invaild "
        case 'auth/invalid-password':
            return "Invalid Password. Password must be at least 6 characters."
        case 'auth/invalid-phone-number':
            return "Invalid phone number"
        case 'auth/invalid-photo-url':
            return "Invalid photo URL"
        case 'auth/operation-not-allowed':
            return "Sign in has been temporarily suspended"
        case 'auth/phone-number-already-exists':
            return "An account with this phone number already exists"
        case 'auth/too-many-requests':
            return "You have entered the incorrect password too many times. Please try again later."
        case 'auth/uid-already-exists':
            return "An account with this UID already exists"
        case 'auth/user-disabled':
            return "Your account has been suspended. Contact support for more details"
        case 'auth/user-not-found':
            return "Couldn't find your account. Check the email address or UID and try again"
        case 'auth/wrong-password':
            return "Wrong password. Try again or click Forgot password to reset it."
        case 'auth/multi-factor-auth-required':
            return "Multi-factor authentication is required, but not present"
        case 'auth/invalid-uid':
            return "The UID entered is invalid. Choose a different UID and try again"
        default:
            return "An unexpected error occurred. Check the internet connection and try again."
    }
}