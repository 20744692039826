import * as React from 'react'
import { Box, Button, Card } from '@mui/material'
import { Stack } from '@mui/system'
import { Link } from 'react-router-dom'

export function Settings() {
    return (
        <Box className='admin-container' sx={{width: "500px", "margin": "0 auto", textAlign: "center"}}s>
        <h1>Website Management</h1>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '1.25rem'}}>
            <Stack gap={4} sx={{mt: 2}}>
                <Card sx={{p: 1}}>
                    <Button component={Link} to='index'>Index Settings</Button>
                </Card>
                <Card sx={{p: 1}}>
                    <Button component={Link} to='shop'>Shop Settings</Button>
                </Card>
                <Card sx={{p: 1}}>
                    <Button component={Link} to='accounts'>Accounts Settings</Button>
                </Card>
                <Card sx={{p: 1}}>
                    <Button component={Link} to='archive'>Archive Settings</Button>
                </Card>
                <Card sx={{p: 1}}>
                    <Button component={Link} to='global'>Global Settings</Button>
                </Card>
            </Stack>
        </Box>
    </Box>
    )
}