import * as React from 'react';
import { useState } from 'react';
import './App.css'
import { ReactComponent as Logo } from './HigherUpsSeal.svg'
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';
import { Offline, Online } from "react-detect-offline";
//Firebase
import { getAuth, onAuthStateChanged, signOut, connectAuthEmulator, getIdTokenResult } from "firebase/auth";
import { connectFunctionsEmulator } from 'firebase/functions';
import { connectFirestoreEmulator } from 'firebase/firestore';
import { auth, functions, db } from './firebase'
import { Route, Routes, Link, useNavigate } from 'react-router-dom';
// Document Pages
import UserLookup from './components/UserLookup'
import CreateAccount from './components/CreateAccount';
import DeleteAccount from './components/DeleteAccount';
import Login from './components/Login'
import UserRoles from './components/UserRoles';
import ManageAdmins from './components/ManageAdmins';
import BanPardonUser from './components/BanPardonUser';

import { Settings } from './components/Settings';
import { Index } from './components/Index';
import IndexSettings from './components/IndexSettings';
import ShopSettings from './components/ShopSettings';
import PasswordReset from './components/PasswordReset';
// import Toolbar from '@mui/material/Toolbar';
import TopNav from './components/TopNav';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
// import Checkbox from '@mui/material/Checkbox';
// import FormControl from '@mui/material/FormControl';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormGroup from '@mui/material/FormGroup'
// import { FormLabel } from '@mui/material';
// import { red } from '@mui/material/colors';
// Icons
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import GavelIcon from '@mui/icons-material/Gavel';
import RestoreIcon from '@mui/icons-material/Restore';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DomainIcon from '@mui/icons-material/Domain';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PublicIcon from '@mui/icons-material/Public';
import LogoutIcon from '@mui/icons-material/Logout';
import WifiOffOutlinedIcon from '@mui/icons-material/WifiOffOutlined';
import CheckIcon from '@mui/icons-material/Check';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';

// import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

const drawerWidth = 240;

export function LogoutButton(props) {
  return (
    <Box sx={{position: 'absolute', top: '10px', right: '15px', display: {md: 'block', xs: 'none'}}}>
      <Button onClick={props.logoutUser} variant='contained'><LogoutIcon sx={{fontSize: '24px'}} /> Logout</Button>
    </Box>
  )
}
export function Android() {
  return (
    <Box className='android'>
      <h1>WE HAVE DETECTED YOU ARE ON ANDROID</h1>
      <Typography component='h2' variant='h5'>TheHigherUps Do <b>NOT</b> Use Android</Typography>
      <Typography component='p' variant='caption'>Your IP Address Has Been Saved And You Will Be Reported To The Authorities</Typography>
      <p></p>
    </Box>
  )
}
// connectAuthEmulator(auth, "http://localhost:9099") 
// connectFunctionsEmulator(functions, "localhost", 5001)
// connectFirestoreEmulator(db, 'localhost', 8080)
export default function App(props) {
  let navigate = useNavigate();
  const [page, setPage] = useState('')
  const [user, setUser] = useState(false)
  const [userAvatar, setUserAvatar] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [uid, setUid] = useState('')
  const [email, setEmail] = useState('')
  const [alertOpen, setAlertOpen] = useState(false)
  const [online, setOnlineStatus] = useState(true)
  const [loginErrorActive, setLoginErrorActive] = useState(false)
  const [loginErrorText, setLoginErrorText] = useState('')
  const [userVerified, setUserVerified] = useState(false)
  const [testAccount, setTestAccount] = useState(false)
  const [themeColor, setThemeColor] = useState('light')
  const [ip, setIp] = useState('')
  const version = "TheHigherUps Services | v0.87"
  const theme = createTheme({
    palette: {
      mode: themeColor,
      custom: {
        light: '#f5f5f5',
        main: '#f5f5f5',
        dark: '#434343',
        contrastText: 'rgba(0, 0, 0, 0.87)',
      },
    },
  });
  // version code | E = emergency update | M = major update, same version # | B =  major bug fix update | T = test 
  function closeAlert() {
    setAlertOpen(false)
  }
  function navClick(nav) {
    setPage(nav)
  }
  React.useEffect(() => {
    const storageTheme = localStorage.getItem('theme')
    if(storageTheme) setThemeColor(storageTheme)
    onAuthStateChanged(auth, (user) => {
      if(user) {
        getIdTokenResult(user).then((idTokenResult) => {
          if(!idTokenResult.claims.admin) {
            logoutUser()
            setLoginErrorActive(true)
            setLoginErrorText('You do not have admin privleges! You must be an admin to sign in')
          } 
          if(idTokenResult.claims.verified) {
            setUserVerified(true)
          } 
          if(idTokenResult.claims.test) {
            setTestAccount(true)
          }
        }) 
        setUser(true)
        setUid(user.uid)
        setPage('User Lookup')
        setUserAvatar(user.photoURL)
        setDisplayName(user.displayName)
        setEmail(user.email)
      } else {
        logoutUser()
        setUser(false)
        setPage('')
        navigate("/login")
      }
    })
    const getIp = async () => {
      await fetch('https://ipapi.co/json')
        .then((response) => response.json())
        .then((data) => {
          setIp(data.ip)
        })
    }
    return () => {
      getIp()
    }
  }, [])
  function loginUser(r) {
    if(r === 1) {
      window.localStorage.setItem('user', true)
    }
    setUser(true)
    setPage('User Lookup')
  }
  function logoutUser() {
    signOut(auth).then(() => {
      setUser(false)
      setPage('')
      //user signed out
    }).catch((error) => {
      setAlertOpen(true)
    })
  }
  const isAndroid = typeof navigator !== 'undefined' && /(android)/i.test(navigator.userAgent)
  return (
  <ThemeProvider theme={theme}>
    <Offline>
      <Snackbar open={true} anchorOrigin={{vertical: 'top', horizontal: 'center'}}>
        <Alert icon={<WifiOffOutlinedIcon />} 
          severity='info'
          variant='filled'
        >
          No internet connection | Attempting to reconnect    
        </Alert>
      </Snackbar>
    </Offline>
    {user ? <TopNav userAvatar={userAvatar} displayName={displayName} logoutUser={logoutUser} setPage={setPage} email={email} verified={userVerified} testAccount={testAccount} setThemeColor={setThemeColor} themeColor={themeColor} /> : null}
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
          display: {md: 'flex', xs: 'none'}
        }}
        variant={user === true ? 'permanent' : 'temporary'}
        anchor="left"
      >
        <Box sx={{textAlign: 'center', padding: ".5rem", verticalAlign: 'text-top', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.25rem '}}>
          <Logo width="48px" height="48px" sx={{verticalAlign: 'text-top'}} />
        <Typography component="h1" variant='h5'>
          TheHigherUps
        </Typography>
        </Box>
        <Divider />
        <List>
        {/* 'User Lookup', `Create Account`, 'Delete Account', 'User Roles', 'Manage Admins', 'Ban/Pardon User' */}
          {[
            {display: 'User Lookup', id: 'lookup'},
            {display: 'Create Account', id: 'create'},
            {display: 'Delete Account', id: 'delete'},
            {display: 'User Roles', id: 'roles'},
            {display: 'Manage Admins', id: 'admins'},
            {display: 'Ban/Pardon User', id: 'ban'}
          ].map((text, index) => (
            <ListItem key={text.id} disablePadding>
              <ListItemButton component={Link} to={text.id} onClick={() => navClick(text)}>
                <ListItemIcon>
                  {[<><PersonSearchIcon /><DoneAllIcon /></>,
                  <><PersonAddAlt1Icon /><DoneAllIcon /></>,
                  <><PersonRemoveIcon /><DoneAllIcon /></>,
                  <><ManageAccountsIcon /><CheckIcon /></>,
                  <><AdminPanelSettingsIcon /><DoneAllIcon /></>,
                  <><GavelIcon/><InfoOutlinedIcon/></>,
                  <RestoreIcon />][index]}
                </ListItemIcon>
                <ListItemText primary={text.display} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          <Typography sx={{textAlign: 'center', textDecoration: 'underline'}}>Website Management</Typography>
          {/* 'Index Settings', 'Shop Settings', 'Accounts Settings', 'Archive Settings', 'Global Settings' */}
          {[
            {display: 'Index Settigns', id: 'index'},
            {display: 'Shop Settings', id: 'shop'},
            {display: 'Accounts Settings', id: 'accounts'},
            {display: 'Archive Settings', id: 'archive'},
            {display: 'Global Settings', id: 'global'},
          ].map((text, index) => (
            <ListItem key={text.id} disablePadding>
              <ListItemButton component={Link} to={`settings/${text.id}`}>
                <ListItemIcon>
                  {[<><DomainIcon /><InfoOutlinedIcon /></>, <ShoppingCartIcon />, <AccountCircleIcon />, <AccountBalanceIcon />, <PublicIcon />][index]}
                </ListItemIcon>
                <ListItemText primary={text.display} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Box sx={{textAlign: 'center'}}>
          <Typography >Connection Established From:</Typography>
          <Typography sx={{textOverflow: 'ellipsis', overflow: 'hidden'}}>{ip ? ip : <Skeleton />}</Typography>
        </Box>
      </Drawer>
      {isAndroid === true ? <Android /> : null}

      {/* {user === false && page !== 'Password Reset' ? <Login loginUser={loginUser} setPage={setPage} setUid={setUid} loginErrorActive={loginErrorActive} loginErrorText={loginErrorText} setLoginErrorActive={setLoginErrorActive} /> : null} */}
      {/* {user && page !== 'Password Reset' ? <LogoutButton logoutUser={logoutUser} /> : null } */}
      {/* {user === false && page === 'Password Reset' ? <PasswordReset setPage={setPage} /> : null } */}
      <Routes>

        <Route index element={<Index />} />
        <Route path='/password-reset' element={<PasswordReset />}  />
        <Route path='/login' element={<Login />} />
        <Route path='/lookup' element={<UserLookup />} />
        <Route path='/create' element={<CreateAccount />} />
        <Route path='/delete' element={<DeleteAccount />} />
        <Route path='/roles' element={<UserRoles />} />
        <Route path='/admins' element={<ManageAdmins />} />
        <Route path='/ban' element={<BanPardonUser />} />
        <Route path='/pardon' element={<BanPardonUser />} />
        <Route path='/settings' element={<Settings />} />
        <Route path='/settings/index' element={<IndexSettings />} />
        <Route path='/settings/shop' element={<ShopSettings />} />
      </Routes>
      {/* {page === "User Lookup" && user === true ? <UserLookup /> : null}
      {page === 'Create Account' && user ? <CreateAccount /> : null}
      {page === 'Delete Account' && user ? <DeleteAccount /> : null}
      {page === 'User Roles' && user ? <UserRoles /> : null}
      {page === 'Manage Admins' && user ? <ManageAdmins /> : null}
      {page === 'Ban/Pardon User' && user ? <BanPardonUser /> : null}
      {page === 'Index Settings' && user ? <IndexSettings /> : null}
      {page === 'Shop Settings' && user ? <ShopSettings /> : null} */}
      <Snackbar 
        open={alertOpen}
        // autoHideDuration={6000} 
        // onClose={closeAlert}
        anchorOrigin={{vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity="error">There was an error signing out of your account</Alert>
      </Snackbar>
    </Box>
    <div className='thehigherups-version'>
      <span>{version}</span>
    </div>
  </ThemeProvider>
  );
}