import * as React from 'react'
import { Box, TextField, Button, Collapse, Alert,
Table, TableBody, TableCell, TableContainer, TableHead,
TableRow, Paper} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'


export default function ShopSettings() {
    const columns = [
        {field: 'product', headerName: 'Product', width: 180},
        {field: 'quantity', headerName: 'Quantity', type: 'number', width: 180, editable: true},
        {field: 'price', headerName: 'Price', type: 'number', width: 180, editable: true}
    ]
    const rows = [
        {
            id: 1,
            product: 'Assault Beans',
            quantity: 78,
            price: 7.00,
        },
        {
            id: 2,
            product: 'Extremely Lethal Beans',
            quantity: 32,
            price: 12.00
        },
        {
            id: 3,
            product: 'Assault Bench',
            quantity: 249,
            price: 32.00
        },
        {
            id: 4,
            product: 'Assault Beans (Cool Beans Edition)',
            quantity: 10,
            price: 55.00
        },
        {
            id: 5,
            product: 'Rape Whistle',
            quantity: 1491,
            price: 8.00
        },
        {
            id: 4,
            product: 'Assault Pipe',
            quantity: 25,
            price: 52.99
        },

    ]
    return (
        <Box className='admin-container' sx={{width: '100%', margin: "0 auto", textAlign: "center"}} >
            <h1>Shop Settings </h1>
            <div style={{ height: 500, width: 600 }} sx={{margin: '0 auto'}}> 
                <DataGrid
                    rows={rows}
                    columns={columns}
                    experimentalFeatures={{ newEditingApi: true }}
                />
            </div>
        </Box> 
    )
}