import * as React from 'react'
import { Box, TextField, Button, Alert, Collapse, Typography, Card, Grid, Select, MenuItem, FormControl, Accordion, AccordionSummary, AccordionDetails, Avatar, IconButton, Divider, InputLabel, Menu, Tooltip, Chip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material"
import { blue, red, yellow, green, indigo, pink } from '@mui/material/colors';
import { LoadingButton } from '@mui/lab';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
import { checkEmail } from '../check-email'
import { CheckErrorCode } from '../firebase-errors';
import CloseIcon from '@mui/icons-material/Close';
import GavelIcon from '@mui/icons-material/Gavel';
import SecurityIcon from '@mui/icons-material/Security';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import StarsIcon from '@mui/icons-material/Stars';
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CodeIcon from '@mui/icons-material/Code';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import ExploreIcon from '@mui/icons-material/Explore';
import LoyaltyIcon from '@mui/icons-material/Loyalty';

export default function UserLookup() {
    const [alertSeverity, setAlertSeverity] = React.useState('info')
    const [alertActive, setAlertActive] = React.useState(false)
    const [alertText, setAlertText] = React.useState('')
    const [buttonLoading, setButtonLoading] = React.useState(false)
    const [updateButtonLoading, setButtonUpdateLoading] = React.useState(false)
    const [userData, setUserData] = React.useState('')
    const [input, setInputValue] = React.useState('')
    const [copied, setCopied] = React.useState(false)
    const [photoDialogOpen, setPhotoDialogOpen] = React.useState(false)
    const [newPhotoUrl, setNewPhotoUrl] = React.useState('')

    const [dataDisplayActive, setDataDisplayActive] = React.useState(false)
    const [dataUid, setDataUid] = React.useState('')
    const [dataDisplayName, setDataDisplayName] = React.useState('')
    const [dataPhoto, setDataPhoto] = React.useState('')
    const [dataEmail, setDataEmail] = React.useState('')
    const [dataDisabled, setDataDisabled] = React.useState(false)
    const [dataEmailVerified, setDataEmailVerified] = React.useState(false)
    const [dataAdmin, setDataAdmin] = React.useState(false)
    const [dataLegendary, setDataLegendary] = React.useState(false)
    const [dataVerified, setDataVerified] = React.useState(false)
    const [dataDangerous, setDataDangerous] = React.useState(false)
    const [dataImportant, setDataImportant] = React.useState(false)
    const [dataTest, setDataTest] = React.useState(false)
    const [dataDangerMessage, setDataDangerMessage] = React.useState('')
    const [dataExplorer, setDataExplorer] = React.useState(false)
    const [dataSupporter, setDataSupport] = React.useState(false)

    const [dataCreationTime, setDataCreationTime] = React.useState('')
    const [dataLastSignInTime, setDataLastSignInTime] = React.useState('')
    const [dataLastRefreshTime, setDataLastRefreshTime] = React.useState('')

    // const [user]
    const userLookup = httpsCallable(functions, 'userLookup')
    const userLookupEmail = httpsCallable(functions, 'userLookupEmail')
    const updateUser = httpsCallable(functions, 'updateUser')
    const signoutUser = httpsCallable(functions, 'signoutUser')
    function alertClose() {
        setTimeout(() => {
            setAlertText('')
            setAlertActive(false)
        }, 3500)
    }
    function clientError(err) {
        setButtonLoading(false)
        setAlertActive(true)
        setAlertSeverity('error')
        setAlertText('A severe error occured! Contact support for help!' + err.code)
        alertClose()
    }
    function closeUserDisplay() {
        setDataDisplayActive(false)
        setDataDisplayName('')
        setDataUid('')
        setDataEmail('')
        setDataDisabled(false)
        setDataEmailVerified(false)
        setDataAdmin(false)
        setDataPhoto('')
        setUserData('')
        setDataLegendary(false)
        setDataCreationTime('')
        setDataLastSignInTime('')
        setDataLastRefreshTime('')
        setDataDangerous(false)
        setDataImportant(false)
        setDataTest(false)
        setDataDangerMessage('')
        setDataExplorer(false)
        setDataSupport(false)
    }
    function returnUserData(result) {
        const data = result.data
        const status = data.status
        const text = data.text
        const user = JSON.stringify(text)
        console.log(text.customClaims)
        setDataDisplayActive(true)
        setDataDisplayName(text.displayName)
        setDataUid(text.uid)
        setDataEmail(text.email)
        setDataDisabled(text.disabled)
        setDataEmailVerified(text.emailVerified)
        if(text.customClaims) {
            setDataAdmin(text.customClaims.admin)
            setDataLegendary(text.customClaims.legendary)
            setDataVerified(text.customClaims.verified)
            setDataImportant(text.customClaims.important)
            setDataDangerous(text.customClaims.dangerous)
            setDataTest(text.customClaims.test)
            setDataDangerMessage(text.customClaims.dangerMessage)
            setDataExplorer(text.customClaims.explorer)
            setDataSupport(text.customClaims.supporter)
        }
        setDataPhoto(text.photoURL)
        setAlertText(`Successfully retrieved user information for ${input}`)
        setAlertSeverity('success')
        setAlertActive(true)
        setUserData(user)
        setDataCreationTime(text.metadata.creationTime)
        setDataLastSignInTime(text.metadata.lastSignInTime)
        setDataLastRefreshTime(text.metadata.lastRefreshTime)
    }
    function updateUserData(event) {
        setButtonUpdateLoading(true)
        event.preventDefault()
        updateUser({uid: dataUid, email: dataEmail, emailVerified: dataEmailVerified, displayName: dataDisplayName, disabled: dataDisabled, photo: dataPhoto })
            .then((result) => {
                setButtonUpdateLoading(false)
                alertClose()
                const data = result.data
                const status = data.status
                const text = data.text
                if(status === 'error') {
                    scrollToTop()
                    const errorCode = CheckErrorCode(text)
                    setAlertText(errorCode)
                    setAlertSeverity('error')
                    setAlertActive(true)
                } else {
                    scrollToTop()
                    setAlertText('Successfully updated user!')
                    setAlertSeverity('success')
                    setAlertActive(true)
                }
            })
            .catch((err) => {
                setButtonUpdateLoading(false)
                // clientError(err)
                console.log(err)
            })
    }
    function scrollToTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }
    function lookupUser(event) {
        setButtonLoading(true)
        closeUserDisplay()
        event.preventDefault()
        const isEmail = checkEmail(input)
        if(isEmail) {
            userLookupEmail({ email: input })
                .then((result) => {
                    setButtonLoading(false)
                    alertClose()
                    const data = result.data
                    const status = data.status
                    const text = data.text
                    const user = JSON.stringify(text)
                    if(status === 'error') {
                        const errorCode = CheckErrorCode(text)
                        setAlertText(errorCode)
                        setAlertSeverity('error')
                        setAlertActive(true)
                    } else {
                        returnUserData(result)
                    }
                })
                .catch((err) => {
                    // clientError(err)
                    console.log(err)
                })
        } else {
            userLookup({ uid: input })
                .then((result) => {
                    setButtonLoading(false)
                    alertClose()
                    const status = result.data.status
                    const text = result.data.text
                    const data = JSON.stringify(text)
                    if(status === 'error') {
                        const errorCode = CheckErrorCode(text)
                        setAlertText(errorCode)
                        setAlertSeverity('error')
                        setAlertActive(true)
                    } else {
                        returnUserData(result)
                    }
                })
                .catch((err) => {
                    // clientError(err)
                    console.log(err)
                })            
            }
            
    }
    function handleLogoutUser() {
        signoutUser({uid: dataUid})
            .then((result) => {
                scrollToTop()
                alertClose()
                const status = result.data.status
                const text = result.data.text
                const data = JSON.stringify(text)
                if(status === 'error') {
                    const errorCode = CheckErrorCode(text)
                    setAlertText(errorCode)
                    setAlertSeverity('error')
                    setAlertActive(true)
                } else {
                    setAlertText('Logged Out User')
                    setAlertSeverity('success')
                    setAlertActive(true)
                }
            })
            .catch((err) => {
                // clientError(err)
                console.log(err)
            })  
    }
    function handleCopyUid() {
        navigator.clipboard.writeText(dataUid)
        setCopied(true)
        setTimeout(() => {
            setCopied(false)
        }, 3500)
    }
    function handleClickPicture() {
        setPhotoDialogOpen(true)
    }
    function handleUpdatePhoto() {
        setNewPhotoUrl('')
        setDataPhoto(newPhotoUrl)
        setPhotoDialogOpen(false)
    }
    function handleClearPhoto() {
        setNewPhotoUrl('')
        setDataPhoto('')
        console.log(dataPhoto, newPhotoUrl)
        setPhotoDialogOpen(false)
    }
    const BannedUser = (
        <Tooltip title="User Banned">
            <GavelIcon sx={{color: red[600], verticalAlign: 'middle'}} />
        </Tooltip>
    )
    const EmailVerified = (
        <Tooltip title="User Email Verified">
            <MarkEmailReadIcon sx={{color: blue[600], verticalAlign: 'middle'}} />
        </Tooltip>
    )
    const AdminUser =  (
        <Tooltip title="User is admin">
            <SecurityIcon sx={{color: blue[600], verticalAlign: 'middle'}} />
        </Tooltip>
    )
    const LegendaryUser = (
        <Tooltip title="Legendary Status">
            <StarsIcon sx={{color: yellow[600], verticalAlign: 'middle'}} />
        </Tooltip>
    )
    const VerifiedUser = (
        <Tooltip title="Verified User">
            <VerifiedIcon sx={{color: blue[600], verticalAlign: 'middle'}} />
        </Tooltip>
    )
    const DangerousUser = (
        <Tooltip title={dataDangerMessage}>
            <Chip icon={<NewReleasesIcon />}  label="This user has been flagged" color='error'/>
        </Tooltip>
    )
    const ImportantUser = (
        <Tooltip title="This user is important">
            <FavoriteIcon sx={{color: red[200], verticalAlign: 'middle'}} />
        </Tooltip>
    )
    const TestUser = (
        <Tooltip title="Official test account of TheHigherUps">
            <CodeIcon sx={{color: green[600], verticalAlign: 'middle'}} />
        </Tooltip>
    )
    const ExplorerUser = (
        <Tooltip title="Explorer Role">
            <ExploreIcon sx={{color: indigo[500], verticalAlign: 'middle'}} />
        </Tooltip>
    )
    const Supporter = (
        <Tooltip title="Early Supporter">
            <LoyaltyIcon sx={{color: pink[500], verticalAlign: 'middle'}} />
        </Tooltip>
    )
    return (
        <Box className='admin-container' sx={{width: "500px", "margin": "0 auto", textAlign: "center", maxWidth: '100vw'}} >
            <h1>Lookup User</h1>
            <Box component="form" sx={{display: "flex", flexDirection: "column", gap: "1rem"}}  onSubmit={lookupUser}>
                <TextField 
                    label="UID or Email" 
                    value={input}
                    onChange={((event)=>setInputValue(event.target.value))}
                    variant='outlined' 
                    required 
                />
                <LoadingButton loading={buttonLoading} variant='contained' type="submit">
                    <span>Search</span>
                </LoadingButton>
                <Collapse 
                    in={alertActive} 
                    onClose={alertClose} 
                >
                    <Alert onClose={alertClose} severity={alertSeverity}>
                        {alertText}
                    </Alert>
                </Collapse>
            </Box>
            <Box hidden={!dataDisplayActive}>
                <Card sx={{display: 'flex', flexDirection: 'column', gap: '1rem', justifyContent: 'center', padding: '1rem', mt: 2}} elevation={3} >
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Tooltip title="Close Display" arrow>
                            <IconButton
                                onClick={closeUserDisplay}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Card sx={{display: 'flex',padding: 2, alignItems: 'center', justifyContent: 'space-around', flexDirection:{xs: 'column', sm: 'row'}, gap:{xs: '1rem', sm:'0'}}} elevation={1}>
                        <Avatar alt={dataDisplayName} src={dataPhoto} sx={{height: 100, width: 100, ':hover':{cursor: 'pointer'}}} onClick={handleClickPicture} variant='rounded' />
                        {/* Add the ability to change user pfp on image click */}
                        <Dialog open={photoDialogOpen} sx={{p: 1}}>
                            <DialogTitle>Update User's Profile Picture</DialogTitle>
                            <DialogContent >
                                <DialogContentText>
                                    Upload a new photo url to update the user's profile picture
                                </DialogContentText>
                                <TextField
                                    label="Profile Picture URL"
                                    variant='standard'
                                    value={newPhotoUrl}
                                    onChange={(event)=>setNewPhotoUrl(event.target.value)}
                                    autoFocus
                                    fullWidth
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClearPhoto}>Clear Photo</Button>
                                <Button onClick={() => setPhotoDialogOpen(false)}>Close</Button>
                                <Button onClick={handleUpdatePhoto} variant='contained'>Update</Button>
                            </DialogActions>
                        </Dialog>
                        <Box>
                            <Typography variant='h5' component='p'>{dataDisplayName}{dataVerified ? VerifiedUser : null}</Typography>
                            <Typography variant='caption'>UID: {dataUid} </Typography>
                            <Tooltip title="Copy UID">
                                <IconButton onClick={handleCopyUid}>
                                    {copied ? <CheckIcon sx={{width: 16, height: 16}} /> : <ContentCopyIcon sx={{width: 16, height: 16}} />}
                                </IconButton>

                            </Tooltip>
                            <br />
                            <Typography variant='caption'>Email: {dataEmail}</Typography>
                        </Box>
                    </Card>
                        <Divider />
                        <TextField 
                            label="Display Name"
                            value={dataDisplayName}
                            onChange={(event) => setDataDisplayName(event.target.value)}
                            helperText={`${dataDisplayName.length}/25`}
                            inputProps={{maxLength: 25}}
                            fullWidth
                            sx={{mt: 2}}
                        />
                        <TextField
                            label="Email"
                            value={dataEmail}
                            onChange={(event) => setDataEmail(event.target.value)}
                            fullWidth
                        />
                        <TextField 
                            label="Phone Number"
                            fullWidth
                            disabled
                        />
                    <div className='user-lookup-input'>
                        <FormControl fullWidth>
                            <InputLabel>Disabled</InputLabel>
                            <Select
                                label='Disabled'
                                value={dataDisabled}
                                onChange={(event) => setDataDisabled(event.target.value)}
                            >
                                <MenuItem value={false}>False</MenuItem>
                                <MenuItem value={true}>True</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel>Email Verified</InputLabel>
                            <Select
                                label='Email Verified'
                                value={dataEmailVerified.toString()}
                                onChange={(event) => setDataEmailVerified(event.target.value)}
                            >
                                <MenuItem value={false}>False</MenuItem>
                                <MenuItem value={true}>True</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <LoadingButton loading={updateButtonLoading} variant='contained' onClick={updateUserData}>
                        <span>Update</span>
                    </LoadingButton>
                    {/* <FormControl fullWidth>
                            <InputLabel>Is Admin</InputLabel>
                            <Select
                                label='Is Admin'
                                value={dataEmailVerified.toString()}
                                disabled
                            >
                                <MenuItem value={false}>False</MenuItem>
                                <MenuItem value={true}>True</MenuItem>
                            </Select>
                    </FormControl> */}
                    <Box sx={{textAlign: 'left'}}>
                        {dataDangerous ? DangerousUser : null}
                    </Box>
                    <Box sx={{textAlign: 'left'}}>
                        {dataDisabled ? BannedUser : null}
                        {dataImportant ? ImportantUser : null}
                        {dataSupporter ? Supporter : null}
                        {dataVerified ? VerifiedUser : null}
                        {dataLegendary ? LegendaryUser : null}
                        {dataExplorer ? ExplorerUser : null}
                        {dataEmailVerified ? EmailVerified : null}
                        {dataAdmin ? AdminUser : null}
                        {dataTest ? TestUser : null}
                    </Box>
                    <Typography sx={{textAlign: 'left'}}>Account Created: {dataCreationTime}</Typography>
                    <Typography sx={{textAlign: 'left'}}>Last Sign In: {dataLastSignInTime}</Typography>
                    <Typography sx={{textAlign: 'left'}}>Last Refresh: {dataLastRefreshTime}</Typography>

                    {/* <Typography>Disabled: {dataDisabled.toString()} | Email Verified: {dataEmailVerified.toString()}</Typography>
                    <Typography>Is Admin: {dataAdmin.toString()}</Typography> */}
                    <Divider />
                    <Typography sx={{wordWrap: 'break-word'}}>{userData}</Typography>
                    <Button variant='contained' color='error' onClick={handleLogoutUser}>Logout User</Button>
                </Card>
            </Box>
            <Box sx={{color: (theme) => theme.palette.mode === 'dark' ? '#151515' : '#f5f5f5'}}>
                <Typography>brian-scher@fbi.gov</Typography>
                <Typography>T3DNDekXlVhtzHhnDX1xxYUjhaO2</Typography>
            </Box>
        </Box>  
    )
}
// export default class UserLookup extends Component {

//     render() {
//         function lookupUser(event) {
//             event.preventDefault()
//         }
//         return(
//         <Box className='admin-container' sx={{width: "500px", "margin": "0 auto", textAlign: "center"}} >
//             <h1>Lookup User</h1>
//             <Box component="form" sx={{display: "flex", flexDirection: "column", gap: "1rem"}}  onSubmit={lookupUser}>
//                 <TextField label="UID or Email" variant='outlined' fullWidth required />
//                 <Button variant='contained' type="submit">Search</Button>
//             </Box>
//             <Collapse in={alertActive}>
//                     <Alert onClose={alertClose}>
//                         {alertText}
//                     </Alert>
//             </Collapse>
//         </Box> 
//         )
//     }
// }