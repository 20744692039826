import * as React from 'react'
import { Box, FormControl, FormControlLabel, Switch, TextField, Button, Collapse, Alert, Card, IconButton, Tooltip, Backdrop, LinearProgress} from '@mui/material'
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { db } from '../firebase';
import ClearIcon from '@mui/icons-material/Clear';

export default function IndexSettings() {
    const [bannerActive, setBannerActive] = React.useState(false)
    const [bannerAlert, setBannerAlertActive] = React.useState(false)
    const [bannerValue, setBannerValue] = React.useState('')
    const [bannerText, setBannerText] = React.useState('')
    const [contactActive, setContactActive] = React.useState(false)
    const [open, setOpen] = React.useState(true)
    const settingsRef = doc(db, 'settings', 'index')
    function banner() {
        updateDoc(settingsRef, {
            notificationBannerActive: !bannerActive
        })
        setBannerActive((bannerActive) => !bannerActive)
    }
    function updateBanner(event) {
        event.preventDefault()
        setBannerAlertActive(true)
        setBannerText(`${bannerValue}`)
        updateDoc(settingsRef, {
            notificationBannerActive: bannerActive,
            notificationBannerText: bannerValue
        })
        setBannerValue('')
        setTimeout(() => {setBannerAlertActive(false)},3000)
    }
    function bannerAlertClose() {
        setBannerAlertActive(false)
    }
    async function handleSetContactActive() {
        setContactActive(!contactActive)
        await updateDoc(settingsRef, {
            acceptResponses: !contactActive
        })
    }
    React.useEffect(() => {
        const unsub = () => onSnapshot(doc(db, 'settings', 'index'), (response) => {
            const data = response.data();
            data.acceptResponses ? setContactActive(true) : setContactActive(false);
            data.acceptResponses ? setContactActive(true) : setContactActive(false);
            data.notificationBannerActive ? setBannerActive(true) : setBannerActive(false);
            setBannerText(data.notificationBannerText);
            setOpen(false)
        })
        // getDoc(doc(db, 'settings', 'index')).then((response) => {
        //     const data = response.data()
        //     console.log(data)
        //     data.acceptResponses ? setContactActive(true) : setContactActive(false)
        //     data.notificationBannerActive ? setBannerActive(true) : setBannerActive(false)
        //     setBannerText(data.notificationBannerText)
        // })
        return () => {
            unsub()
        }
    }, [])
    return (
        <Box className='admin-container' sx={{width: "500px", "margin": "0 auto", textAlign: "center"}}s>
            <Alert severity='info'>These settings are non-functional and are meant for UI purposes only</Alert>
            <h1>Index Settings</h1>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={open}
            >
                <LinearProgress color="inherit" />
            </Backdrop>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '1.25rem'}}>
                <Card sx={{padding: 1}}>
                    <FormControl 
                        fullWidth
                    >
                        <FormControlLabel 
                            control={<Switch />}
                            label='Accept Repsonses from Contact Form'
                            checked={contactActive}
                            onChange={handleSetContactActive} 
                        />
                    </FormControl>
                </Card>
                <Card sx={{padding: 1}}>
                    <FormControl 
                        sx={{display: 'flex', gap: '1rem'}} 
                        fullWidth 
                        component='form' 
                        onSubmit={updateBanner}
                    >
                        <FormControlLabel 
                            control={<Switch />} 
                            label="Notification Banner" 
                            checked={bannerActive}
                            onChange={banner} 
                        />
                        <TextField 
                            label='Banner Text' 
                            helperText={bannerActive ? bannerText: 'Banner Disabled'}
                            value={bannerValue}
                            onChange={(event) => setBannerValue(event.target.value)}
                            autoComplete='off'
                            placeholder={bannerText}
                            disabled={!bannerActive} 
                            required
                        />
                        <Button 
                            variant='contained' 
                            type='submit' 
                            disabled={!bannerActive}
                            fullWidth
                        >
                            Update Banner
                        </Button>
                        <Collapse in={bannerAlert}>
                            <Alert onClose={bannerAlertClose}>Banner Successfully Updated</Alert>
                        </Collapse>
                    </FormControl>
                </Card>
            </Box>

        </Box>
    )
}