import * as React from 'react'
import { Box, TextField, Button, Snackbar, Alert, Collapse, Select, FormControl, FormControlLabel, MenuItem, InputLabel } from "@mui/material"
import { LoadingButton } from '@mui/lab';
import { red } from '@mui/material/colors';
import { functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export default function UserRoles() {
    const [alertActive, setAlertActive] = React.useState(false)
    const [alertSeverity, setAlertSeverity] = React.useState('info')
    const [alertText, setAlertText] = React.useState('')
    const [inputList, setInputList] = React.useState([])
    const [userInput, setUserInput] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [role, setRole] = React.useState('')
    const [message, setMessage] = React.useState('')
    const [customRole, setCustomRole] = React.useState('')
    const addRole = httpsCallable(functions, 'addRole')
    const removeRole = httpsCallable(functions, 'removeRole')
    function alertClose() {
        setAlertActive(false)
        setAlertText('')
    }
    function handleUserRoles(event) {
        event.preventDefault()
        // setAlertActive(true)
    }
    function handleAddRole() {
        setLoading(true)
        addRole({uid: userInput, role: role, dangerMessage: message, customRole:customRole}).then((result) => returnStatus(result))
    }
    function handleRemoveRole() {
        setLoading(true)
        removeRole({uid: userInput, role: role, dangerMessage: message, customRole:customRole}).then((result) => returnStatus(result))
    }
    function addNewInput(index) {
        setInputList(inputList.concat(<TextField label="Add Role" key={inputList.length} sx={{width: '100%'}} autoComplete='off' />))
    }
    function returnStatus(result) {
        const data = result.data
        const status = data.status
        if (status === 'error') {
            const errorText = data.text
            setAlertSeverity('error')
            setAlertText(errorText)
            setAlertActive(true)
        } else {
            setAlertSeverity('success')
            setAlertText('Successfully updated user roles') 
            setAlertActive(true)
        }
        setTimeout(() => {
            alertClose()
        }, 3500)
        setLoading(false)
    }
    function handleMessageChange(event) {
        setMessage(event.target.value)
    }
    function handleCustomRoleChange(event) {
        setCustomRole(event.target.value)
    }
    return (
        <Box className='admin-container' sx={{width: "500px", "margin": "0 auto", textAlign: "center"}} >
            <h1>User Roles</h1>
            <Box sx={{mb: 2}}>
                <Alert severity='info'>
                    <s>
                    User roles do not work as expected. Removing a role from a user will only take affect after the user signs in again.<b> This is a bug that will be fixed soon.</b>
                    </s>
                    <br />
                    User now gets force signed out when updating a role
                </Alert>
                <Alert severity='warning' sx={{mt: 1}}>
                    <s>
                    You are unable to set custom roles with custom values. This is a <b>bug and not an intended feature</b>. This bug is planned to be fixed soon.
                    </s>
                    <br />
                    You are now able to create custom roles
                </Alert>
            </Box>
            <Box component="form" sx={{display: "flex", flexDirection: "column", gap: "1rem"}}  onSubmit={handleUserRoles}>
                <TextField 
                    label="UID" 
                    variant='outlined' 
                    value={userInput}
                    onChange={(event) => setUserInput(event.target.value)}
                    fullWidth 
                    required 
                />
                <FormControl>
                    <InputLabel>Select Role</InputLabel>
                    <Select
                        label="Select Role"
                        value={role}
                        onChange={(event) => setRole(event.target.value)}
                        sx={{textAlign: 'left'}}
                    >
                        <MenuItem value={''}><em>None</em></MenuItem>
                        <MenuItem value={'important'}>Important Role</MenuItem>
                        <MenuItem value={'legendary'}>Legendary Role</MenuItem>
                        <MenuItem value={'verified'}>Verified Role</MenuItem>
                        <MenuItem value={'dangerous'}>Dangerous Role</MenuItem>
                        <MenuItem value={'test'}>Test User</MenuItem>
                        <MenuItem value={'explorer'}>Explorer Role</MenuItem>
                        <MenuItem value={'custom'}>Custom Role</MenuItem>
                        <MenuItem value={'early'} disabled><s>Early Supporter Role</s><em>No Longer Available</em></MenuItem>
                    </Select>
                </FormControl>
                <Box hidden={Boolean(role !== 'dangerous')}>
                    <TextField 
                        label="Dangerous User Message"
                        value={message}
                        variant='standard'
                        onChange={handleMessageChange}
                        inputProps={{maxLength:120}}
                        helperText={`${message.length}/120`}
                        multiline
                        fullWidth
                        
                    />
                </Box>
                <Box hidden={Boolean(role !== 'custom')}>
                    <TextField 
                        label="Custom User Role"
                        value={customRole}
                        variant='standard'
                        onChange={handleCustomRoleChange}
                        inputProps={{maxLength:20}}
                        helperText={`${customRole.length}/20`}
                        multiline
                        fullWidth
                        
                    />
                </Box>
                {/* <Box sx={{display: 'flex', gap: '1rem', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center'}}>
                    <TextField label='Add Role' sx={{flexGrow:'1'}} required/>
                    <IconButton onClick={addNewInput}>
                        <AddCircleOutlineIcon />
                    </IconButton>
                    {inputList}
                </Box> */}
                <Box sx={{display: 'flex', gap: '0.75rem'}}>
                    <LoadingButton 
                        loading={loading}
                        onClick={handleAddRole} 
                        variant='contained' 
                        type="submit" 
                        fullWidth
                    >
                        <span>Add Role</span>
                    </LoadingButton>
                    <LoadingButton
                        loading={loading}
                        onClick={handleRemoveRole} 
                        variant='contained'
                        type="submit" 
                        fullWidth 
                        color='error'
                    >
                        <span>Remove Role</span>
                        {/* Remove Roll */}
                    </LoadingButton>
                </Box>
                <Button variant='outlined' onClick={() => setInputList([])}>Clear Inputs</Button>
                <Collapse in={alertActive}>
                    <Alert severity={alertSeverity}>
                        {alertText}
                    </Alert>
                </Collapse>
            </Box>
            {/* <Snackbar 
                open={alertActive} 
                sx={{marginTop: '2rem'}} 
                onClose={alertClose} 
                autoHideDuration={3000}
                anchorOrigin={{horizontal: 'right', vertical:'bottom'}}
            >
                <Alert onClose={alertClose} severity='success'>
                    Sucessfully Updated User Roles
                </Alert>
            </Snackbar> */}
        </Box>  
    )
}