import { Box, Button, Card } from '@mui/material'
import { Stack } from '@mui/system'
import { Link } from 'react-router-dom'

export function Index() {
    return (
        <Box className='admin-container' sx={{width: "500px", "margin": "0 auto", textAlign: "center"}}s>
        <h1>Website Management</h1>
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '1.25rem'}}>
            <Stack gap={4} sx={{mt: 2}}>
                <Card sx={{p: 1}}>
                    <Button component={Link} to='/lookup'>User Lookup</Button>
                </Card>
                <Card sx={{p: 1}}>
                    <Button component={Link} to='/create'>Create Account</Button>
                </Card>
                <Card sx={{p: 1}}>
                    <Button component={Link} to='/delete'>Delete Account</Button>
                </Card>
                <Card sx={{p: 1}}>
                    <Button component={Link} to='/roles'>User Roles</Button>
                </Card>
                <Card sx={{p: 1}}>
                    <Button component={Link} to='/admins'>Manage Admins</Button>
                </Card>
            </Stack>
        </Box>
    </Box>
    )
}