import * as React from 'react'
import { Box, Button, Grid, Card, TextField, FormControlLabel, Checkbox, Typography, Alert, Collapse, Snackbar } from "@mui/material"
import { ReactComponent as Logo } from '../HigherUpsSeal.svg'
import { Triangle } from 'react-loader-spinner'
import { signInWithEmailAndPassword,onAuthStateChanged } from 'firebase/auth'
import { auth } from '../firebase'
import { CheckErrorCode } from '../firebase-errors'
import { useNavigate, Link } from 'react-router-dom'
export default function Login(props) {
    let navigate = useNavigate()
    const [username, setUsernameValue] = React.useState('')
    const [password, setPasswordValue] = React.useState('') 
    const [showPassword, setShowPassword] = React.useState(false)
    const [id, setIdValue] = React.useState('')
    const [alertActive, setAlertActive] = React.useState(false)
    const [alertText, setAlertText] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const usernames = ['Classified', 'TheHigherUps', 'Test', 'Admin', 'Alpine', 'Tolve', 'Brian Scher']
    const ids = ['0001', '1', '01', 'ERG4 SLFUYURX K0U ASC2', 'UY7J SDUDTWNJ PMH KSK1', 'NKGP WFEMFVNE WDU S9M2']
    const time = Math.floor(Math.random() * (2222 - 1250) + 1250)
    React.useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if(user)  {
                navigate('/lookup')
            }
        })
    })
    function handleLogin(event) {
        event.preventDefault()
        setLoading(true)
        signInWithEmailAndPassword(auth, username, password)
            .then((userCredential) => {
                props.setUser(true)
                navigate('/lookup')
            })
            .catch((error) => {
                const errorCode = CheckErrorCode(error.code)
                setLoading(false)
                setAlertActive(true)
                setAlertText(errorCode)
                console.log(error)
                navigate('/login')
                setTimeout(() => {
                    setAlertActive(false)
                    setAlertText('')
                }, 3500)
            })
    }
    function quickLogin() {
        setAlertActive(true)
        setAlertText('OneClick Sign In has been disabled')
        // setLoading(true)
        setTimeout(() => {
            setAlertActive(false)
            setAlertText('')
            // props.loginUser(1)
        }, 3500)
    }
    function toggleShowPassword() {
        setShowPassword((showPassword) => !showPassword)
    }
    function handleLoginErrorClose() {
        props.setLoginErrorActive(false)
    }
    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
        >
            <Triangle
                height="125"
                width="125"
                color="#356a9b"
                ariaLabel="triangle-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={loading}
            />
            <Grid 
                item xs={3} 
                sx={{minWidth: { xs: '100%', sm: '500px'}}} 
                hidden={loading}
            >
                
                <Card 
                    component='form' 
                    sx={{display: 'flex', flexDirection: 'column', gap: '1.25rem', padding: '2rem'}} elevation={3} 
                    onSubmit={handleLogin} 
                    autoComplete='off'
                >
                    <Box 
                        sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', gap: '0.5rem'}}
                    >
                        <Logo/> 
                        <Typography component='h1' variant='h4' >
                            Sign In
                        </Typography>
                        <Typography variant='subtitle1'>Use TheHigherUps Admin Account</Typography>
                    </Box>
                    <TextField 
                        label="Email" 
                        type='email'
                        value={username}
                        onChange={(event) => setUsernameValue(event.target.value)}
                        fullWidth
                        required
                    />
                    <TextField
                        label="HigherUps-ID"
                        type='text'
                        value={id}
                        onChange={(event) => setIdValue(event.target.value)}
                        fullWidth
                        disabled
                    />
                    <TextField 
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        value={password}
                        onChange={(event) => setPasswordValue(event.target.value)} 
                        fullWidth
                        required
                    />
                    <FormControlLabel 
                        control= {
                            <Checkbox />
                        }
                        onChange={toggleShowPassword}
                        label="Show Password"
                    />
                    <Button 
                        type="submit"
                        variant="contained"
                    >
                        Sign in 
                    </Button>
                    <Collapse in={alertActive}>
                        <Alert severity='error'>{alertText}</Alert>
                    </Collapse>
                    <Button component={Link} replace to='/password-reset'>
                        Forgot Password
                    </Button>
                    <Button onClick={quickLogin}>
                        OneClick Sign in
                    </Button>
                </Card>
            </Grid>
            <Snackbar 
                open={props.loginErrorActive}
                onClose={handleLoginErrorClose}
                autoHideDuration={6000}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Alert severity='error'>{props.loginErrorText}</Alert>
            </Snackbar>
        </Grid>
    )
}