import * as React from 'react'
import { Box, TextField, Button, Tooltip, FormControlLabel, 
    Checkbox, Alert, Collapse, Dialog, DialogTitle, DialogContent, 
    DialogContentText, DialogActions, RadioGroup, Radio  } from "@mui/material"
import { red } from "@mui/material/colors"
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
import { checkEmail } from '../check-email';
import { CheckErrorCode } from '../firebase-errors';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
export default function DeleteAccount() {
    const [dialogOpen, setDialogOpen] = React.useState(false)
    const [alertActive, setAlertActive] = React.useState(false)
    const [alertText, setAlertText] = React.useState('')
    const [alertSeverity, setAlertSeverity] = React.useState('info')
    const [userInput, setUserInput] = React.useState('')

    const deleteUser = httpsCallable(functions, 'deleteAccount')
    function handleClose() {
        setDialogOpen(false)
    }
    function openDialog(event) {
        event.preventDefault()
        setDialogOpen(true)
    }
    function dialogConfirm() {
        const email = checkEmail(userInput)
        if(email) {
            setAlertText('Email not supported, Use UID instead')
            setAlertSeverity('warning')
            setAlertActive(true)
            setTimeout(() => {
                setAlertActive(false)
            }, 3500)
        } else {
            deleteUser({ uid: userInput }).then((result) => {
                const status = result.data.status
                const text = result.data.text
                if(status === 'error') {
                    const errorCode = CheckErrorCode(text)
                    setAlertSeverity('error')
                    setAlertText(errorCode)
                    setAlertActive(true)
                }else {
                    setAlertSeverity('success')
                    setAlertText(text)
                    setAlertActive(true)
                }
                setTimeout(() => {
                    setAlertActive(false)
                }, 3500)
            })
        }
        handleClose()
    }
    function alertClose() {
        setAlertActive(false)
        setAlertText('')
    }
    return (
        <Box className='admin-container' sx={{width: "500px", "margin": "0 auto", textAlign: "center"}} >
            <h1>Delete User</h1>
            <Box component="form" sx={{display: "flex", flexDirection: "column", gap: "1rem"}} onSubmit={openDialog}>
                <TextField 
                    label="UID or Email"
                    variant='outlined'
                    value={userInput}
                    onChange={(event) => setUserInput(event.target.value)}
                    fullWidth
                    required
                />
                {/* <Box display="flex" flexDirection='column'>
                    <RadioGroup
                        defaultValue='normal'
                    >
                        <FormControlLabel
                            control={
                                <Radio />
                            }
                            value='normal'
                            label='Delete data after 30 days'
                        />
                        <FormControlLabel
                            control={
                                <Radio />
                            }
                            value='expedite'
                            label='Delete data immediately'
                        />
                        <FormControlLabel
                            control={
                                <Radio />
                            }
                            value='none'
                            label='Do not delete user data'
                        />
                    </RadioGroup>
                    <FormControlLabel
                        control={<Checkbox />}
                        label="Notify User"
                    />
                </Box> */}
                <Tooltip title="This action is permanent. Are you sure you want to continue?">
                    <Button 
                        variant='contained' 
                        color='error'
                        type='submit'
                    >
                        <DeleteForeverIcon />
                            Delete User
                        <DeleteForeverIcon />
                    </Button>
                </Tooltip>
                <Collapse in={alertActive}>
                    <Alert severity={alertSeverity} onClose={alertClose}>
                        {alertText}
                    </Alert>
                </Collapse>
                <Dialog 
                    open={dialogOpen}
                    onClose={handleClose}
                >
                    <DialogTitle>Delete User Account?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Deleting a user account means that access to the account will be terminated immediately and all account data will be deleted after 30 days unless otherwise selected
                        </DialogContentText>
                        <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                            <Button onClick={dialogConfirm}>Delete Account</Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </Box>
        </Box> 
    )
}