import * as React from 'react'
import { Box, TextField, FormGroup, FormControlLabel, Checkbox, Button, Collapse, Alert, Tooltip, IconButton } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
import { CheckErrorCode } from '../firebase-errors';
import PasswordIcon from '@mui/icons-material/Password';
export default function CreateAccount() {
    const [showPassword, setShowPassword] = React.useState(false)
    const [buttonLoading, setButtonLoading] = React.useState(false)
    const [alertActive, setAlertActive] = React.useState(false)
    const [alertText, setAlertText] = React.useState('')
    const [alertSeverity, setAlertSeverity] = React.useState('info') //error, warning, info, success
    const [name, setNameValue] = React.useState('')
    const [email, setEmailValue] = React.useState('')
    const [password, setPasswordValue] = React.useState('')
    const [passwordConfirm, setPasswordConfirmValue] = React.useState('')
    const [number, setNumberValue] = React.useState('')
    const [photoURL, setPhotoURLValue] = React.useState()
    const createAccount = httpsCallable(functions, 'createAccount')
    function handlePasswordClick() {
        setShowPassword((showPassword) =>  !showPassword)
    }
    function handleCreateAccount(event) {
        event.preventDefault()
        setButtonLoading(true)
        if(password !== passwordConfirm) {
            setAlertSeverity('warning')
            setAlertText('Password values do not match')
            setAlertActive(true)
            setTimeout(() => {
                handleAlertClose()
                setButtonLoading(false)
            },3500)
        } else {
            createAccount({ email: email, password: password, name: name, photoURL: photoURL })
                .then((result) => {
                    setButtonLoading(false)
                    const status = result.data.status
                    const text = result.data.text
                    if(status === 'error') {
                        const errorCode = CheckErrorCode(text)
                        setAlertSeverity('error')
                        setAlertText(errorCode)
                        setAlertActive(true)
                    }else {
                        setAlertSeverity('success')
                        setAlertText(text)
                        setAlertActive(true)
                        clearAllInputs()
                    }
                    setTimeout(() => {
                        handleAlertClose()
                    }, 3500)
                })
                .catch((error) => {
                    setButtonLoading(false)
                    console.log(error.code)
                })
        }
    }
    function clearAllInputs() {
        setNameValue('')
        setEmailValue('')
        setPasswordValue('')
        setPasswordConfirmValue('')
        setNumberValue('')
        setPhotoURLValue('')
    }
    function handleAlertClose() {
        setAlertActive(false)
    }
    function handleGeneratePassword() {
        var chars = "0123456789abcdefghijklmnopqrstuvwxyz!$ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var fullChars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        var passwordLength = 8;
        var password = "";
        for (var i = 0; i <= passwordLength; i++) {
            var randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber +1);
        }
        setPasswordValue(password)
        setPasswordConfirmValue(password)
        navigator.clipboard.writeText(password)

    }
    return(
        <Box className='admin-container' id='create' sx={{width: "500px", "margin": "0 auto", textAlign: "center"}} >
            <h1>Create User</h1>
            <Box component="form" sx={{display: "flex", flexDirection: "column", gap: "1rem"}} onSubmit={handleCreateAccount} autoComplete='off'>
            <TextField 
                label="Display Name" 
                type="text" 
                value={name}
                onChange={(event) => setNameValue(event.target.value)}
                helperText={`${name.length}/25`}
                inputProps={{maxLength: 25}}
                fullWidth 
                required
            />
            <TextField 
                label="Email" 
                type="email" 
                value={email}
                onChange={(event) => setEmailValue(event.target.value)}
                variant='outlined' 
                fullWidth 
                required
            />
            <Box sx={{ display: 'flex', alignItems:'center', gap: 1}}>
                <TextField 
                    label="Password" 
                    type={showPassword ? 'text' : 'password'} 
                    value={password}
                    onChange={(event) => setPasswordValue(event.target.value)}
                    variant='outlined' 
                    fullWidth 
                    required 
                />
                <Tooltip title="Generate Password and Copy To Clipboard">
                    <IconButton onClick={handleGeneratePassword}>
                        <PasswordIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <TextField 
                label="Confirm Password" 
                type={showPassword ? 'text' : 'password'} 
                value={passwordConfirm}
                onChange={(event) => setPasswordConfirmValue(event.target.value)}
                variant='outlined' 
                fullWidth 
                required 
            />
            <FormControlLabel
                control={
                    <Checkbox />
                } 
                label="Show Password"
                onChange={handlePasswordClick}
            />
            <TextField 
                label="Photo URL"
                type="text"
                value={photoURL}
                onChange={(event) => setPhotoURLValue(event.target.value)}
                variant='outlined' 
                fullWidth
            />
            <TextField 
                label="Phone Number (Optional)"
                helperText="Phone number currently not in use"
                type="tel"
                value={number}
                onChange={(event) => setNumberValue(event.target.value)}
                variant='outlined' 
                fullWidth 
                disabled
            />
            <Box sx={{display: "flex"}}>
            {/* <FormGroup sx={{flexDirection: "row", justifyContent: "center"}}>
                <FormControlLabel 
                    control={
                        <Checkbox checkedIcon={<GavelIcon />} sx={{'&.Mui-checked': {color: red[600]}}} />
                    } 
                    label="Suspend User" 
                />
                <FormControlLabel 
                    control={
                        <Checkbox checkedIcon={<VerifiedUserIcon />}/>
                    } 
                    label="Admin Role" 
                />
                <FormControlLabel 
                    control={
                        <Checkbox sx={{'&.Mui-checked': {color: yellow[800]}}} checkedIcon={<VerifiedIcon />}/>
                    } 
                    label="LEGENDARY Role" 
                />
                <FormControlLabel 
                    control={
                        <Checkbox icon={<IndeterminateCheckBoxIcon/>}/>
                    } 
                    label="Early Supporter Role" 
                    disabled 
                />
                <FormControlLabel 
                    control={
                        <Checkbox sx={{'&.Mui-checked': {color: red[600]}}} checkedIcon={<PriorityHighIcon />} />
                    }  
                    label="High Alert" 
                />
                <FormControlLabel 
                    control={<Checkbox checkedIcon={<PriorityHighIcon />} />}  
                    label="High Priority" 
                />
            </FormGroup> */}
            </Box>
                <Collapse in={alertActive}>
                    <Alert 
                        onClose={handleAlertClose}
                        severity={alertSeverity}
                    >
                        {alertText}
                    </Alert>     
                </Collapse>
                <LoadingButton
                    loading={buttonLoading}
                    type="submit" 
                    startIcon={<PersonAddAlt1Icon /> }
                    variant='contained'
                >
                    <span>Create User</span> 
                </LoadingButton>
            </Box>
        </Box>     
    )
}
// export default class CreateAccount extends Component {
//     render() {
//         const [showPassword, setShowPassword] = React.useState(false)
//         return(
            
//             <Box className='admin-container' id='create' sx={{width: "500px", "margin": "0 auto", textAlign: "center"}} >
//                 <h1>Create User</h1>
//                 <Box component="form" sx={{display: "flex", flexDirection: "column", gap: "1rem"}}>
//                 <TextField label="Email" type="email" variant='outlined' fullWidth required />
//                 <TextField label="Password" type="password" variant='outlined' fullWidth required />
//                 <FormControlLabel
//                     control={
//                         <Checkbox />
//                     } 
//                     label="Show Password"
//                     onChange={showPassword()}
//                 />
//                 <TextField label="Confirm Password" variant='outlined' fullWidth required />
//                 <TextField label="Phone Number" type="tel" variant='outlined' fullWidth required />
//                 <Box sx={{display: "flex"}}>
//                 <FormGroup sx={{flexDirection: "row", justifyContent: "center"}}>
//                     <FormControlLabel 
//                         control={
//                             <Checkbox checkedIcon={<GavelIcon />} sx={{'&.Mui-checked': {color: red[600]}}} />
//                         } 
//                         label="Suspend User" 
//                     />
//                     <FormControlLabel 
//                         control={
//                             <Checkbox checkedIcon={<VerifiedUserIcon />}/>
//                         } 
//                         label="Admin Role" 
//                     />
//                     <FormControlLabel 
//                         control={
//                             <Checkbox sx={{'&.Mui-checked': {color: yellow[800]}}} checkedIcon={<VerifiedIcon />}/>
//                         } 
//                         label="LEGENDARY Role" 
//                     />
//                     <FormControlLabel 
//                         control={
//                             <Checkbox icon={<IndeterminateCheckBoxIcon/>}/>
//                         } 
//                         label="Early Supporter Role" 
//                         disabled 
//                     />
//                     <FormControlLabel 
//                         control={
//                             <Checkbox sx={{'&.Mui-checked': {color: red[600]}}} checkedIcon={<PriorityHighIcon />} />
//                         }  
//                         label="High Alert" 
//                     />
//                     <FormControlLabel 
//                         control={<Checkbox checkedIcon={<PriorityHighIcon />} />}  
//                         label="High Priority" 
//                     />
//                 </FormGroup>
//                 </Box>
//                 <Button type="submit" variant='contained'>Create User</Button>
//                 </Box>
//             </Box> 
//         )

            
//     }
// }