import * as React from 'react'
import { Box, TextField, Button, Snackbar, Alert, Collapse, Typography} from '@mui/material'
import { red } from '@mui/material/colors'
import { Link } from 'react-router-dom'
export default function BanPardonUser() {
    const [alertActive, setAlertActive] = React.useState(false)
    const [alertText, setAlertText] = React.useState('')
    const [alertPriority, setAlertPriority] = React.useState(true)
    function alertClose() {
        setAlertActive(false)
    }
    function handleBanPardonUser(event) {
        event.preventDefault()
        setAlertActive(true)
        const x = setTimeout(() => {
            setAlertActive(false)
        }, 3000)
    }
    function banUser() {
        setAlertText('User Successfully Banned from TheHigherUps')
        setAlertPriority(true)
    }
    function pardonUser() {
        setAlertText('User Successfully Pardoned from TheHigherUps')
        setAlertPriority(false)
    }
    return (
        <Box className='admin-container' sx={{width: "500px", "margin": "0 auto", textAlign: "center"}} >
            <h1>Ban or Pardon User</h1>
            <Box component="form" sx={{display: "flex", flexDirection: "column", gap: "1rem"}} onSubmit={handleBanPardonUser}>
                <Alert severity='info'>
                    <Typography sx={{color: 'inherit'}}>
                        This feature will be removed in version 1.00 as the function is already built into the  
                         <Button component={Link} to="/lookup">User Lookup</Button> feature
                    </Typography>
                </Alert>
                <TextField label="UID or Email" variant='outlined' fullWidth required />
                <Button 
                    variant='contained'
                    type="submit"
                    onClick={banUser}
                    color='error'
                >
                    Ban User
                </Button>
                <Button variant='contained' type="submit" onClick={pardonUser}>Pardon User</Button>
            </Box>
            <Collapse 
                in={alertActive} 
                sx={{marginTop: '2rem'}} 
                onClose={alertClose} 
                autoHideDuration={3000}
                anchorOrigin={{horizontal: 'right', vertical:'bottom'}}
            >
                <Alert onClose={alertClose} severity={alertPriority ? 'error' : 'success'}>
                    {alertText}
                </Alert>
            </Collapse>
        </Box> 
    )
}