import * as React from 'react'
import { Box, TextField, Button, Alert, Collapse} from '@mui/material'
import { red, blue } from '@mui/material/colors';
import { LoadingButton } from '@mui/lab';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
import { checkEmail } from '../check-email'
import { CheckErrorCode } from '../firebase-errors';
export default function ManageAdmins() {
    const [userInput, setUserInput] = React.useState('')
    const [alertActive, setAlertActive] = React.useState(false)
    const [alertText, setAlertText] = React.useState('')
    const [alertSeverity, setAlertSeverity] = React.useState('info')
    const [addButtonLoading, setAddButtonLoading] = React.useState(false)
    const [removeButtonLoading, setRemoveButtonLoading] = React.useState(false)
    const addAdmin = httpsCallable(functions, 'addAdmin')
    const removeAdmin = httpsCallable(functions, 'removeAdmin')
    function alertClose() {
        setAlertActive(false)
    }
    function handleAddRemoveAdmins(event) {
        event.preventDefault()
    }
    function handleAddAdmin() {
        const isEmail = checkEmail(userInput)
        if(isEmail) {
            setAddButtonLoading(true)
            addAdmin({ type: 'email', email: userInput})
                .then((result) => {
                    setAddButtonLoading(false)
                    const status = result.data.status
                    const text = result.data.text
                    if(status === 'error') {
                        const errorCode = CheckErrorCode(text)
                        setAlertSeverity('error')
                        setAlertText(errorCode)
                        setAlertActive(true)
                        closeAlert()
                    }else {
                        setAlertSeverity('success')
                        setAlertText(text)
                        setAlertActive(true)
                        clearAllInputs()
                        closeAlert()
                    }
                })
            // setAlertText('Only UID is supported currently. Enter the users UID.')
            // setAlertSeverity('warning')
            // setAlertActive(true)
            // closeAlert()
        } else {
            setAddButtonLoading(true)
            addAdmin({ type: 'uid', uid: userInput })
                .then((result) => {
                    setAddButtonLoading(false)
                    const status = result.data.status
                    const text = result.data.text
                    if(status === 'error') {
                        const errorCode = CheckErrorCode(text)
                        setAlertSeverity('error')
                        setAlertText(errorCode)
                        setAlertActive(true)
                        closeAlert()
                    }else {
                        setAlertSeverity('success')
                        setAlertText(text)
                        setAlertActive(true)
                        clearAllInputs()
                        closeAlert()
                    }
                })
                .catch((error) => {
                    setAddButtonLoading(false)
                    console.log(error)
                })
        }
    }
    function clearAllInputs() {
        setUserInput('')
    }
    function handleRemoveAdmin() {
        const isEmail = checkEmail(userInput)
        if(isEmail) {
            setRemoveButtonLoading(true)
            removeAdmin({ type: 'email', email: userInput})
                .then((result) => {
                    setRemoveButtonLoading(false)
                    const status = result.data.status
                    const text = result.data.text
                    if(status === 'error') {
                        const errorCode = CheckErrorCode(text)
                        setAlertSeverity('error')
                        setAlertText(errorCode)
                        setAlertActive(true)
                        closeAlert()
                    } else {
                        setAlertSeverity('success')
                        setAlertText(text)
                        setAlertActive(true)
                        clearAllInputs()
                        closeAlert()
                    }   
                })
        } else {
            setRemoveButtonLoading(true)
            removeAdmin({ type: 'uid', uid: userInput })
                .then((result) => {
                    setRemoveButtonLoading(false)
                    const status = result.data.status
                    const text = result.data.text
                    if(status === 'error') {
                        const errorCode = CheckErrorCode(text)
                        setAlertSeverity('error')
                        setAlertText(errorCode)
                        setAlertActive(true)
                        closeAlert()
                    }else {
                        setAlertSeverity('success')
                        setAlertText(text)
                        setAlertActive(true)
                        clearAllInputs()
                        closeAlert()
                    }
                })
        }
            
    }
    function closeAlert() {
        setTimeout(() => {
            setAlertActive(false)
            setAlertText('')
        }, 3500)
    }
    return (
        <Box className='admin-container' sx={{width: "500px", "margin": "0 auto", textAlign: "center"}} >
            <h1>Add or Remove Admins</h1>
            <Box component="form" sx={{display: "flex", flexDirection: "column", gap: "1rem"}} onSubmit={handleAddRemoveAdmins}>
                <TextField
                    label="UID or Email" 
                    variant='outlined' 
                    value={userInput}
                    onChange={(event) => setUserInput(event.target.value)}
                    fullWidth
                    required 
                />
                <Collapse 
                    in={alertActive} 
                    onClose={alertClose} 
                >
                    <Alert onClose={alertClose} severity={alertSeverity}>
                        {alertText}
                    </Alert>
                </Collapse>
                <LoadingButton
                    loading={addButtonLoading}
                    variant='contained' 
                    type="submit" 
                    onClick={handleAddAdmin}
                >
                    <span>Add Admin</span>
                </LoadingButton>
                <LoadingButton 
                    loading={removeButtonLoading}
                    variant='contained' 
                    type="submit" 
                    onClick={handleRemoveAdmin}
                    color='error'
                >
                    <span>Remove Admin</span>
                </LoadingButton>
            </Box>

        </Box> 
    )
}