import { initializeApp } from 'firebase/app'
import { getAuth } from "firebase/auth"
import { getFunctions } from 'firebase/functions'
import { getFirestore } from 'firebase/firestore'

//Test Config 
const firebaseConfig = {
    apiKey: "AIzaSyBNPiH1LMKKtEejYWtnFXcBty1iuwA8SmA",
    authDomain: "higherupstest.firebaseapp.com",
    databaseURL: "https://higherupstest-default-rtdb.firebaseio.com",
    projectId: "higherupstest",
    storageBucket: "higherupstest.appspot.com",
    messagingSenderId: "591123674444",
    appId: "1:591123674444:web:d4cce2ad11bf57d1514be9"
}
  
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const functions = getFunctions(app)
export const db = getFirestore(app)
export default app;